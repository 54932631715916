(<template>
  <booking-form :validate-func="formValidation"
                :success-handling-func="handleSuccess"
                :error-handling-func="handleError"
                :sections-list="sectionsList"
                :submit-button-text="submitButtonText"
                :summary-obj="summaryObj"
                :send-info-func="sendInfoFunc" />
</template>)

<script>
  import {mapActions} from 'vuex';
  import OpenBookingPrototype from '@/prototypes/OpenBookingPrototype';

  export default {
    components: {},
    extends: OpenBookingPrototype,
    computed: {
      assignmentInformationSection() {
        return {
          title: this.$pgettext('translation', 'Translation information'),
          lines: [{
            components: [{
              name: 'multiple-language-selection',
              params: {
                showFromLang: true
              }
            }]
          }, {
            components: [{
              name: 'deadline-section',
              params: {
                isDateOptional: true,
                showSupportHint: true,
                showReset: true,
                miniDeadlineDays: 2,
                hideTime: true,
                tooltipText: this.$pgettext('translation', 'We will contact you as soon as possible, and will then confirm if we are able to deliver within your required deadline.')
              }
            }]
          }, {
            components: [{
              name: 'project-attachments-section'
            }]
          }, {
            components: [{
              name: 'project-extra-info-section',
              params: {
                extraInfoText: this.$pgettext('translation', 'Is there anything we should know about your document?')
              }
            }]
          }]
        };
      },
      invoiceInformationSection() {
        return {
          title: this.$pgettext('translation', 'Invoice information'),
          lines: [{
            components: [{
              name: 'invoice-info-section',
              params: {
                fieldsConfig: this.fieldsConfig,
                showPaymentMethodSection: false
              }
            }]
          }]
        };
      },
      summaryObj() {
        return {
          title: this.$pgettext('translation', 'Translation Project Information'),
          showFileUploadCheckbox: true,
          job: {
            languageFromId: this.languageFrom,
            targetLanguageIds: this.targetLanguages,
            deadline: this.deadline,
            organization: this.chosenOrganization.name,
            department: {
              departmentId: this.department,
              departmentsList: this.departmentsList
            },
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phoneNumber: `${this.phoneCode}${this.phoneNumber}`,
            orgNumber: this.orgNumber,
            bookingRef: this.bookingRef,
            paymentBookingRef: this.paymentBookingRef,
            caseNumber: this.caseNumber,
            description: this.extraInfo,
            attachments: this.attachments
          }
        };
      },
      submitButtonText() { return this.$pgettext('translation', 'Submit'); }
    },
    methods: {
      ...mapActions({
        preselectStaticData: 'PostingNotifierStore/preselectProjectStaticData'
      }),
      formValidation() {
        this.removeErrors();
        const assignmentInfoIsValid = this.validateSourceTargetLanguagesSection()
          & this.validateAttachedFiles({maxSize: 50, maxFiles: 100, fileIsRequired: false})
          & this.validateDeadlineSection();
        const organizationFieldIsValid = this.validateOrgSpecificFields();
        const orgNumberValid = this.validateOrganizationField();
        return this.validateSharedFields() & organizationFieldIsValid & assignmentInfoIsValid & orgNumberValid;
      },
      sendInfoFunc() {
        return this.$store.dispatch('PostTranslationProjectStore/createLogoutProject');
      },
      goToSuccessPage() {
        this.$router.push(this.$makeRoute({name: 'SuccessOpenBookingTranslation', params: {organization: this.openBookingType}}));
      },
      handleError(error) {
        if (error?.data?.errors && error?.data?.errors.length) {
          const errors = error.data.errors;
          if (errors.includes('Invalid email')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$pgettext('translation', 'Please check the email address you have provided for spelling errors, and try again.')
            });
          }
          if (errors.includes('Organisation number is invalid')) {
            this.setError({
              fieldName: 'orgNumber',
              errorText: this.$pgettext('translation', 'Organisation number is invalid.')
            });
          }
          if (errors.includes('User with given email does not exist')) {
            this.setError({
              fieldName: 'email',
              errorText: this.$pgettext('translation', 'Customer with this email does not exist. Please use "I\'m a new customer" option instead.')
            });
          }
          if (errors['global!']) {
            this.setError({
              fieldName: 'orgNumber',
              errorText: errors['global!'][0]
            });
          }
          this.$emit('postassignfailed');
          this.$store.dispatch('ModalStore/closeModal');
        } else {
          setTimeout(() => {
            this.$store.commit('ModalStore/setModal', {
              component: 'error-modal',
              data: {
                error
              }
            });
          }, 0);
        }
        setTimeout(() => {
          this.$scrollToErrors();
        });
      }
    },
    mounted() {
      if (this.dataPromise) {
        this.dataPromise.then(() => {
          this.preselectStaticData();
        });
      } else {
        this.preselectStaticData();
      }
    },
    beforeRouteLeave(to, from, next) {
      this.$store.dispatch('PostTranslationProjectStore/clearStore');
      next();
    }
  };
</script>
